import * as React from "react";
import { graphql } from "gatsby";

import Bio from "../components/bio";
import Layout from "../components/layout";
import Seo from "../components/seo";
import PostLists from "../components/PostList";

const _ = require('lodash')

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes
  let categories = [];

  _.each(posts, node => {
    if (_.get(node, 'frontmatter.category')) {
      categories.push(node.frontmatter.category)
    }
  });
  categories = _.uniq(categories);

  return (
    <Layout location={location} title={siteTitle} categories={categories}>
      <Seo title="All posts" />
      <Bio />
      <PostLists posts={posts} />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          category
        }
      }
    }
  }
`
